<template>
  <b-overlay :show="loading">
    <div class="formBoder">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <b-form @submit.prevent="handleSubmit(submitData)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col lg="12" md="12" sm="12" xs="12">
            <ValidationProvider name="Page Name" vid="page_name" rules="required" v-slot="{ errors }">
              <b-form-group
                class="row"
                label-cols-sm="12"
                id="page_name"
                label-for="Page Name"
              >
              <template v-slot:label>
                Page Name <span>*</span>
              </template>
                <b-form-input
                  id="page_name"
                  v-model="form.page_name"
                  placeholder="Enter Page Name"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" md="12" sm="12" xs="12">
            <ValidationProvider name="Page Slug" vid="page_slug" rules="required" v-slot="{ errors }">
              <b-form-group
                class="row"
                label-cols-sm="12"
                id="page_slug"
                label-for="Page Slug"
              >
              <template v-slot:label>
                Page Slug <span>*</span>
              </template>
                <b-form-input
                  id="page_slug"
                  v-model="form.page_slug"
                  placeholder="Enter Page Slug"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" md="12" sm="12" xs="12">
            <ValidationProvider name="Description" vid="description" rules="required">
              <b-form-group
                class="row"
                label-cols-sm="12"
                label-for="description"
                slot-scope="{ valid, errors }"
                >
               <template v-slot:label>
                Description
               </template>
               <!-- <vue-editor
                id="text"
                v-model="form.description"
                :state="errors[0] ? false : (valid ? true : null)"
              ></vue-editor> -->
              <quill-editor
                  style="height:300px;margin-bottom: 50px;"
                  v-model="form.description"
                  :state="errors[0] ? false : (valid ? true : null)"
                />
            </b-form-group>
          </ValidationProvider>
          </b-col>
       </b-row>
      <div class="row mt-3">
        <div class="col-sm-3"></div>
          <div class="col text-right">
            <b-button type="submit" variant="primary" class="mr-2">{{ SaveButton }}</b-button>
            &nbsp;
            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Cancel</b-button>
          </div>
      </div>
     </b-form>
    </ValidationObserver>
    </div>
  </b-overlay>
</template>
<script>
import RestApi, { apiBaseURL } from '@/config'

export default {
  props: ['editItem'],
  data () {
    return {
      SaveButton: this.editItem ? 'Update' : 'Save',
      form: {
        page_name: '',
        page_slug: '',
        description: ''
      },
      errors: [],
      valid: null,
      loading: false,
      parentList: []
    }
  },
  watch: {
    'form.page_name': function (newVal) {
      const nameLowerCase = newVal.toLowerCase()
      const pageSlug = nameLowerCase.replaceAll(' ', '-')
      this.form.page_slug = pageSlug
    }
  },
  created () {
    if (this.editItem) {
      this.form = this.editItem
    }
  },
  methods: {
    async submitData () {
      this.loading = true
      let result = ''
      if (this.form.id) {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/update_page_data', this.form)
      } else {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/store_page_data', this.form)
      }
      if (result.success) {
        this.$emit('loadList', true)
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.$bvModal.hide('modal-1')
        this.loading = false
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
<style>
 .formBoder {
    border: 1px solid;
    margin: 5px;
    padding: 35px;
    font-size: 13px
 }
 .input-group-text {
   height: 30.5px!important;
 }
</style>
